import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer as DefaultToastContainer, toast } from 'react-toastify';
import { markNotificationAsSeen } from '../../redux/notifications/notificationActions';

export function ToastContainer() {
  const [position, setPosition] = useState({ bottom: 0 });

  const dispatch = useDispatch();
  const unseenNotifications = useSelector((state) => state.notification.unseenNotifications);

  const markAllNotificationsAsSeen = () => {
    const unseenNotificationIds = unseenNotifications.map(
      (notification) => notification._id,
    );

    if (unseenNotificationIds.length > 0) {
      dispatch(markNotificationAsSeen(unseenNotificationIds));
    }

    toast.dismiss();

    localStorage.setItem('Notifications/hideAllTriggered', true);
  };

  toast.onChange((numberOfToasts) => {
    const bottomPosition = numberOfToasts === 1 ? 180 : 300;
    setPosition({ bottom: bottomPosition });

    if (numberOfToasts === 1) {
      localStorage.setItem('Notifications/hideAllTriggered', false);
    }
  });

  return (
    <div>
      <DefaultToastContainer
        containerId="notification-toast-container"
        newestOnTop
      />
      {unseenNotifications.length > 1 && (
      <Button
        type="button"
        className="dismiss-notifications-button"
        onClick={markAllNotificationsAsSeen}
        style={{
          bottom: position.bottom,
        }}
      > 
        Hide All
      </Button>
      )}
    </div>
  );
}
