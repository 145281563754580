import React from 'react';
import { toast } from 'react-toastify';
import { apiHelper } from '../../redux/apiHelper';

import CallIcon from '../../assets/images/notification-call.svg';
import OpenInNewIcon from '../../assets/images/open-in-new.svg';
import SuccessNotificationIcon from '../../assets/images/notification-success.svg';
import ErrorNotificationIcon from '../../assets/images/notification-error.svg';
import NotificationCloseIcon from '../../assets/images/notification-close.svg';

import styles from './Notification.module.css';


const icons = {
  success: <img src={SuccessNotificationIcon} alt="Success" />,
  error: <img src={ErrorNotificationIcon} alt="Error" />,
  close: <img src={NotificationCloseIcon} alt="Close" />,
  info: null,
};

const handleClickToCall = async (id) => {
  try {
    const res = await apiHelper('post', `/api/voip/call/${id}`);
    if (res && res.data && res.data.success) {
      const { message } = res.data;
  
      toast.success(message);
    }
  } catch (error) {
    console.log(error.message);
  
    toast.error("Error: cant't make call");
  }
};

export function NotificationActionHeader({
  firstLabel, secondLabel, date, onRedirect, userId, onUserProfileClick,
}) {
  let clientLeadName = secondLabel;

  if (secondLabel) {
    const [firstPart, lastPart] = secondLabel.split(' ');

    clientLeadName = secondLabel.length > 25 ? `${firstPart} ${lastPart.slice(0, 5)}...` : `${secondLabel}`;
  }

  const handleClickUserProfile = () => {
    if (!firstLabel) {
      onUserProfileClick();
    }
  };

  return (
    <div className={styles.notificationActionHeader}>
      <div className={styles.notificationHeaderActions}>
        {!firstLabel && (
          <>
            <img src={CallIcon} alt="Call" onClick={() => handleClickToCall(userId)} className={styles.notificationActionIcon} />
            <img src={OpenInNewIcon} alt="Open in new" className={styles.notificationActionIcon} onClick={onRedirect} />
          </>
        )}
        <span>
          <span className={styles.firstLabel}>{firstLabel}</span>
          <span className={styles.secondLabel} onClick={handleClickUserProfile}>
            <span>{clientLeadName}</span>
          </span>
        </span>
      </div>
      <span className={styles.notificationDate}>{date}</span>
    </div>
  );
}

/*
  type: success, error, info
  title: string
  description: string
  additionalInfo?: string
  footer?: React.Component
  notificationData?: object
  preview?: boolean
  onClose?: () => void
  userId?: string
  onUserProfileClick?: () => void
*/
function Notification({
  type,
  title,
  description,
  additionalInfo,
  footer,
  onClose,
  onClick,
  onRedirect,
  notificationData,
  preview = false,
  userId,
  onUserProfileClick,
}) {
  const infoNotification = type === 'info';

  return (
    <div className={styles.notificationContainer}>
      {infoNotification && (
      <div className={styles.notificationHeader}>
        <NotificationActionHeader
          {...notificationData}
          onRedirect={onRedirect}
          userId={userId}
          onUserProfileClick={onUserProfileClick}
        />
      </div>
      )}
      <div className={styles.notificationContent}>
        <div
          className={`${styles.notificationTitle} ${
            infoNotification ? styles['pt-12px'] : ''
          }`}
        >
          <div className={styles.notificationTitleText}>
            {!infoNotification ? icons[type] : null}
            <span>{title}</span>
          </div>
          {preview ? (
            <button
              type="button"
              className={styles.closeButton}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              {icons.close}
            </button>
          ) : null}
        </div>
        <div className={styles.notificationDescription}>
          <span>{description}</span>
        </div>
        <div
          className={styles.additionalInfo}
          onClick={() => {
            if (additionalInfo) {
              onRedirect();
            }
          }}
        >
          <span>{additionalInfo}</span>
        </div>
      </div>
    </div>
  );
}

export default Notification;
