export const voipColumns = [
  {
    name: 'Agent',
    field: 'agent.firstName,agent.lastName',
    selected: true,
    order: 1,
  },
  {
    name: 'Provider',
    field: 'providerId',
    selected: true,
    order: 2,
  },
  {
    name: 'Extension',
    field: 'extension',
    selected: true,
    order: 3,
  },
  {
    name: 'Brand Name',
    field: 'brandId',
    selected: true,
    order: 4,
  },
  {
    name: 'Status',
    field: 'isEnabled',
    selected: true,
    order: 5,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
    order: 6,
  },
];
    
  
