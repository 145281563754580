import React, { useContext, useState } from 'react';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { BsTrashFill } from 'react-icons/bs';
import Switch from '@mui/material/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { omitColumn, sortColumnByOrderConfig } from './helper';
import { ModalFilters } from '../components';
import { ModalContext } from '../context';
import { deleteVoipById, editVoip } from '../redux/voip/voipActions';
import { EditVoipModal } from '../pages/Voip/modals/editVoipModal';
import { useTableSorting, SortWrapper } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';

export const voipSchema = ({
  columnConfig,
  fullNameFilter,
  setFullNameFilter,
  providerFilter,
  setProviderFilter,
  usersFilters,
  setUserFilters,
  extensionFilter,
  setExtensionFilter,
  brandFilter,
  setBrandFilter,
  statusFilter,
  setStatusFilter,
  handleSort,
}) => {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const voipProviders = useSelector((state) => state.voip.voipProviders);
  const brands = useSelector((state) => state.brands);
  const uId = useSelector((state) => state.crmUser.crmUserInfo._id);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanEditVoip = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('voip_edit');
  const isUserCanDeleteVoip = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('voip_delete');

  const handleChangeEnableStatus = (row) => {
    const { _id, isEnabled } = row;

    dispatch(editVoip(_id, uId, { ...row, isEnabled: !isEnabled }));
  };

  const handleEditVoip = (row) => {
    showModal({
      bodyContent: <EditVoipModal row={row} />,
      headerContent: <h3>Edit VoIP</h3>, 
    });
  };

  const handleDeleteVoip = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteVoipById(id));
      }
    });
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const voipColumns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('agent.firstName agent.lastName')}>
            <div className="d-flex flex-row">
              <span>Agent</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('agent.firstName agent.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="fullname"
            autoComplete="off"
            value={fullNameFilter ?? ''}
            onChange={({ target: { value } }) => setFullNameFilter(value)}
          />
        </div>
      ),
      minWidth: '200px',
      cell: ({ agent }) => {
        const agentFullName = `${agent?.firstName} ${agent?.lastName}`;
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {agent ? agentFullName : '-'}
          </span>
        );
      },
      label: 'Agent',
      reorder: true,
      omit: omitColumn(columnConfig, 'Agent'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('provider.name')}>
            <div className="d-flex flex-row">
              <span>Provider</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('provider.name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            data={voipProviders} 
            filters={providerFilter} 
            setFilters={setProviderFilter} 
            tabName="Provider" 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '100px',
      cell: ({ provider: { name } }) => name,
      label: 'Provider',
      reorder: true,
      omit: omitColumn(columnConfig, 'Provider'),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des pt-2" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('extension')}>
            <div className="d-flex flex-row">
              <span>Extension</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('extension')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="fullname"
            autoComplete="off"
            value={extensionFilter ?? ''}
            onChange={({ target: { value } }) => setExtensionFilter(value)}
          />
        </div>
      ),
      minWidth: '100px',
      cell: ({ extension }) => extension,
      label: 'Extension',
      reorder: true,
      omit: omitColumn(columnConfig, 'Extension'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('brand.name')}>
            <div className="d-flex flex-row">
              <span>Brand Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('brand.name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            data={brands} 
            filters={brandFilter} 
            setFilters={setBrandFilter} 
            tabName="Brand Name" 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '140px',
      cell: ({ brand: { name } }) => name,
      label: 'Brand Name',
      reorder: true,
      omit: omitColumn(columnConfig, 'Brand Name'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isEnabled')}>
            <div className="d-flex flex-row">
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isEnabled')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            data={[{ name: 'Enabled', status: true }, { name: 'Disabled', status: false }]}
            filters={statusFilter}
            setFilters={setStatusFilter}
            tabName="Status" 
            searchParams={{ id: 'status', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
        </div>
      ),
      minWidth: '100px',
      cell: ({ isEnabled }) => (isEnabled ? 'Enabled' : 'Disabled'),
      label: 'Disabled',
      reorder: true,
      omit: omitColumn(columnConfig, 'Status'),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          {isUserCanEditVoip && (
            <Switch checked={row.isEnabled} color="success" onChange={() => handleChangeEnableStatus(row)} />
          )}
          {isUserCanEditVoip && (
            <button type="button" className="btn btn-success btn-sm me-1 p-1" onClick={() => handleEditVoip(row)}>
              <FontAwesomeIcon icon={faPencil} />
            </button>
          )}
          {isUserCanDeleteVoip && (
            <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => handleDeleteVoip(row._id)}>
              <BsTrashFill role="button" size={18} />
            </button>
          )}
        </div>
      ),
      minWidth: '250px',
      label: 'Actions',
      reorder: true,
      omit: omitColumn(columnConfig, 'Actions'),
    },
  ];

  return sortColumnByOrderConfig(voipColumns, columnConfig);
};
