import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from './components/ToastContainer/ToastContainer';
import Routes from './routes/index';
import 'react-toastify/dist/ReactToastify.css';
import store from './redux/combineReducers';
import { ModalContextProvider } from './context';

export function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <ModalContextProvider>
          <Routes />
        </ModalContextProvider>
      </BrowserRouter>
    </Provider>
  );
};
